import { h } from 'preact'
import iconList from '../../images/icons/list2.svg'
import iconPencil from '../../images/icons/pencil.svg'
import iconPlus from '../../images/icons/plus.svg'
import iconShare from '../../images/icons/share.svg'
import { route } from '../../services/router'
import { toMinutes } from '../../services/time'
import { IconButton } from '../Common/IconButton/IconButton'
import styles from './Controls.scss'

export const Controls = (props: { message: string; timestamp: number }) => {
	const messageEncoded = encodeURIComponent(props.message)

	return (
		<div className={styles.controls}>
			<IconButton
				className={styles.iconButton}
				onClick={() => route('/history')}
				alt="History"
				src={iconList}
			/>
			<IconButton
				className={styles.iconButton}
				onClick={() =>
					route(`/edit/${toMinutes(props.timestamp)}/${messageEncoded}`)
				}
				alt="Edit"
				src={iconPencil}
			/>
			<IconButton
				className={styles.iconButton}
				onClick={() => route('/')}
				alt="New"
				src={iconPlus}
			/>
			<IconButton
				className={styles.iconButton}
				onClick={() => {
					try {
						navigator.share({ text: props.message })
					} catch (e) {
						// ignore
					}
				}}
				src={iconShare}
			/>
		</div>
	)
}
