import fitty from 'fitty'
import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { toMilliseconds } from '../../services/time'
import { Clock } from '../Common/Clock/Clock'
import { FullscreenButton } from '../Common/FullscreenButton/FullscreenButton'
import { Page } from '../Common/Page/Page'
import { Controls } from '../Controls/Controls'
import styles from './CountdownPage.scss'

export type CountdownPageProps = {
	message?: string
	timestamp?: number
	path?: string
}

export const CountdownPage = (props: CountdownPageProps) => {
	const timestamp = toMilliseconds(Number(props.timestamp))

	useEffect(() => {
		fitty('#message', {
			maxSize: 100,
			minSize: 40,
			multiLine: true,
		})
	}, [])

	return (
		<Page className={styles.countdown} title={props.message}>
			<div className={styles.message}>
				<h1 id="message">{props.message}</h1>
			</div>
			<Clock timestamp={timestamp} />
			<FullscreenButton />
			<Controls message={props.message || ''} timestamp={timestamp} />
		</Page>
	)
}
