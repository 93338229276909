import { h } from 'preact'
import Router from 'preact-router'
import { useEffect } from 'preact/hooks'
import * as history from '../services/history'
import { onNavigation } from '../services/router'
import { ConfigPage } from './ConfigPage/ConfigPage'
import { CountdownPage } from './CountdownPage/CountdownPage'
import { History } from './History/History'

export function App() {
	useEffect(() => {
		const removeListener = onNavigation(({ path }) => {
			history.add(path)
		})
		return () => removeListener()
	})

	return (
		<div className="root">
			<Router>
				<ConfigPage path="/" />
				<ConfigPage path="/edit/:timestamp/:message" />
				<CountdownPage path="/:timestamp/:message?" />
				<History path="/history" />
			</Router>
		</div>
	)
}
