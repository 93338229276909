import { h, JSX } from 'preact'

type Props = JSX.HTMLAttributes<HTMLElement> & {
	src: string
}

export function Svg(props: Props) {
	const { src: __html, ...rest } = props
	return <i dangerouslySetInnerHTML={{ __html }} {...rest} />
}
