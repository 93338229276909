import { h, render } from 'preact'
import * as config from '../config'
import './styles/index.scss'

if (process.env.NODE_ENV === 'production') {
	import('./services/analytics').then((analytics) =>
		analytics.init(config.googleAnalytics.id)
	)
	import('./services/offline').then((offline) => offline.init())
}

function init() {
	const { App } = require('./components/App')
	render(<App />, document.body)
}

if (module.hot) {
	module.hot.accept('./components/App', () => requestAnimationFrame(init))
}

init()
