import { h } from 'preact'
import { pad, pluralize } from '../../../../services/format'
import styles from './ClockNumber.scss'

export const ClockNumber = (props: { number: number; label: string }) => {
	const digits = pad(props.number, 2, '0')
	const label = pluralize(props.number, props.label)

	return (
		<div className={styles.clockNumber} data-testid="ClockNumber">
			<div className={styles.digit} data-testid="ClockNumberDigits">
				{digits}
			</div>
			<div className={styles.title}>{label}</div>
		</div>
	)
}
