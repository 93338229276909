import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import styles from './Page.scss'

const titleSuffix = 'A Countdown Clock'

export const Page = (props: {
	children?: any
	className?: string
	title?: string
}) => {
	useEffect(() => {
		if (props.title) {
			document.title = `${props.title} | ${titleSuffix}`
		} else {
			document.title = titleSuffix
		}
	}, [props.title])

	return (
		<section className={`${styles.page} ${props.className}`}>
			<div className={styles.content}>{props.children}</div>
		</section>
	)
}
