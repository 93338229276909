import { h } from 'preact'
import iconCross from '../../../images/icons/cross.svg'
import { Params } from '../../../services/params'
import { route } from '../../../services/router'
import { Clock } from '../../Common/Clock/Clock'
import { IconButton } from '../../Common/IconButton/IconButton'
import styles from '../History.scss'

export const HistoryEntry = ({
	entry: { message, path, timestamp },
	onRemove,
}: {
	entry: Params
	onRemove: (path: string) => void
}) => {
	return (
		<li className={styles.historyEntry} key={path} onClick={() => route(path)}>
			<span className={styles.details}>
				<span className={styles.message}>{message}</span>
				<Clock timestamp={timestamp} />
			</span>
			<span>
				<IconButton
					className={styles.deleteButton}
					alt="Delete"
					onClick={() => onRemove(path)}
					src={iconCross}
				/>
			</span>
		</li>
	)
}
