import classNames from 'classnames'
import { h, JSX } from 'preact'
import styles from './Button.scss'

type Props = JSX.HTMLAttributes<HTMLButtonElement> & {
	loading?: boolean
	loadingText?: string
	secondary?: boolean
	transparent?: boolean
	[key: string]: any
}

export const Button = ({
	children,
	className,
	disabled,
	loading,
	loadingText = 'Loading…',
	secondary,
	transparent,
	...rest
}: Props) => {
	return (
		<button
			className={classNames(
				styles.button,
				{
					[styles.loading]: loading,
					[styles.secondary]: secondary,
					[styles.transparent]: transparent,
				},
				className
			)}
			disabled={disabled || loading}
			type="button"
			{...rest}
		>
			{loading ? loadingText : children}
		</button>
	)
}
