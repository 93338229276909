import classNames from 'classnames'
import { h, JSX } from 'preact'
import { Svg } from '../Svg/Svg'
import styles from './Icon.scss'

type Props = JSX.HTMLAttributes<HTMLElement> & {
	src: string
}

export function Icon({ className, ...rest }: Props) {
	return <Svg {...rest} className={classNames(styles.icon, className)} />
}
