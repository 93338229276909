import fitty from 'fitty'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { diff } from '../../../services/time'
import styles from './Clock.scss'
import { ClockNumber } from './ClockNumber/ClockNumber'

const zero = Object.freeze({
	years: 0,
	months: 0,
	weeks: 0,
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	totalSeconds: 0,
})

export function Clock(props: { timestamp: number }) {
	const [time, setTime] = useState(diff(props.timestamp))

	useEffect(() => {
		if (props.timestamp <= Date.now()) {
			return setTime(zero)
		}

		const interval = setInterval(() => setTime(diff(props.timestamp)), 500)
		setTime(diff(props.timestamp))

		return () => clearInterval(interval)
	}, [props.timestamp])

	useEffect(() => {
		fitty('[data-testid=ClockNumberDigits]', {
			multiLine: false,
		})
	}, [])

	const showYears = time.years > 0
	const showWeeks = time.years + time.weeks > 0
	const showDays = time.years + time.weeks + time.days > 0

	return (
		<div className={styles.clock}>
			{showYears && <ClockNumber number={time.years} label="Year" />}
			{showWeeks && <ClockNumber number={time.weeks} label="Week" />}
			{showDays && <ClockNumber number={time.days} label="Day" />}
			<ClockNumber number={time.hours} label="Hour" />
			<ClockNumber number={time.minutes} label="Minute" />
			<ClockNumber number={time.seconds} label="Second" />
		</div>
	)
}
