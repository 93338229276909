import { h } from 'preact'
import * as history from '../../services/history'
import { Page } from '../Common/Page/Page'
import styles from './History.scss'
import { HistoryEntry } from './HistoryEntry/HistoryEntry'

export function History(props: { path?: string }) {
	return (
		<Page title="History">
			<div className={styles.history}>
				{history
					.get()
					.reverse()
					.map((entry) => (
						<HistoryEntry
							key={entry.path}
							entry={entry}
							onRemove={history.remove}
						/>
					))}
			</div>
		</Page>
	)
}
