import classNames from 'classnames'
import { h, JSX } from 'preact'
import { Icon } from '../Icon/Icon'
import styles from './IconButton.scss'

type Props = {
	alt?: string
	className?: string
	'data-testid'?: string
	disabled?: boolean
	secondary?: boolean
	onClick?: JSX.MouseEventHandler<HTMLButtonElement>
	src: string
	text?: string
}

export function IconButton(props: Props) {
	return (
		<button
			className={classNames(styles.iconButton, props.className, {
				[styles.secondary]: props.secondary,
			})}
			data-testid={props['data-testid']}
			disabled={props.disabled}
			onClick={props.onClick}
			type="button"
		>
			<Icon className={styles.icon} src={props.src} title={props.alt} />
			{!!props.text && <span className={styles.text}>{props.text}</span>}
		</button>
	)
}
