import { h } from 'preact'
import iconEnlarge from '../../../images/icons/enlarge.svg'
import { IconButton } from '../IconButton/IconButton'
import styles from './FullscreenButton.scss'

let fullscreenEnabled = 'webkitFullscreenEnabled'
let requestFullscreen = 'webkitRequestFullscreen'

if ('mozFullScreen' in document) {
	fullscreenEnabled = 'mozFullScreenEnabled'
	requestFullscreen = 'mozRequestFullScreen'
}

const isSupported = (document as any)[fullscreenEnabled]

const enterFullscreen = () =>
	(document.documentElement as any)[requestFullscreen]()

export const FullscreenButton = () => {
	if (!isSupported) {
		return null
	}

	return (
		<IconButton
			alt="Toggle fullscreen"
			className={styles.fullscreen}
			onClick={enterFullscreen}
			src={iconEnlarge}
		/>
	)
}
